<template>
  <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
  <div class="login-form" v-if="isMobile() == true & token == null">
    <input type="text" placeholder="Введите ваш код" v-model="tokeninput">
    <button @click="tokenset">Вход</button>
  </div>
  <div class="menu" :class="{green: theme == 'green', blue: theme == 'blue', right: mobile_position == 'left' && mobile_position == 'right'}" v-if="isMobile() == true">

    <div class="left material-symbols-rounded" v-if="mobile_position == 'left'" @click="mobile_position = 'center'">arrow_forward_ios</div>

    <div class="left material-symbols-rounded" v-if="mobile_position == 'center'" @click="mobile_position = 'left'">menu</div>
    <div class="left material-symbols-rounded" v-if="mobile_position == 'center'" @click="mobile_position = 'right'">account_box</div>

    <div class="left material-symbols-rounded" v-if="mobile_position == 'right'" @click="mobile_position = 'center'">arrow_back_ios</div>
    
  </div>
  <div class="chat" :class="{green: theme == 'green', blue: theme == 'blue', dark: theme == 'dark', chatmobile: isMobile()}">
    <div @scroll="infinity($event)" class="left section" :class="{active: show_leftbar}" v-if="isMobile() == false && messenger_type == 'global' || isMobile() == true && mobile_position == 'left'">
      <ul>
        <div class="searchbar" v-if="show_leftbar">
          <input type="text" v-model="search" @keyup="onFilter" placeholder="Поиск...">

          <div class="filters-button" @click="filters_opened = !filters_opened">
            <span class="material-symbols-rounded">tune</span>
          </div>
        </div>
        <div class="filters" v-if="filters_opened">
          <div class="filter">
            <label for="stage_id">Поиск по сообщениям</label>
            <input type="text" v-model="message_filter" placeholder="Поиск по сообщениям">
          </div>
          <div class="filter">
            <label for="stage_id">Этап</label>
            <select name="stage" id="stage_id" @change="onFilter" v-model="filter_stage_value">
              <option value="">-</option>
              <option v-for="stage in stages_list" :key="stage" :value="stage.id">{{ stage.title }}</option>
            </select>
          </div>
          <div class="filter">
            <label for="responsible">Текущий ответственный</label>
            <select name="responsible" id="responsible" @change="onFilter" v-model="filter_responsible_value">
              <option value="">-</option>
              <option  v-for="resp in filters_responsible" :key="resp" :value="resp.username">{{resp.first_name}} ({{resp.username}})</option>
            </select>
          </div>
          <div class="filter">
            <label for="channels">Канал</label>
            <select name="channels" id="channels" @change="onFilter" v-model="filter_channel_value">
              <option value="">-</option>
              <option v-for="channel in filters_channels" :key="channel" :value="channel.id">{{channel.name_channel}}</option>
            </select>
          </div>
          <div class="filter">
            <label for="platform">Платформа</label>
            <select name="platform" id="platform" @change="onFilter" v-model="filter_platform_value">
              <option value="">-</option>
              <option v-for="platform in filters_platforms" :key="platform" :value="platform">{{platform}}</option>
            </select>
          </div>
          <div class="filter">
            <label for="tags_filter">Теги</label>
            <vue3-tags-input :tags="tags_filter" placeholder="Введите теги для поиска" @on-tags-changed="handleChangeTagFilter"/>
          </div>
        </div>
        <li v-for="chat in filtered_chats" :key="chat" @click="change_chat(chat.id)" :class="{ active: chat.id==active_chat }">
          <div class="message-container">
            <img :src="'https://dunkback.mvpproject.io/' + chat.avatar" alt="egor avatar">
            <div class="text">
              <div class="top" v-if="show_leftbar">
                <div class="username">{{sliceText10(chat.first_name)}}</div>
                <div class="time">{{formatMoscowTime(chat.message_last__created_at)}}</div>
              </div>
              <div class="bottom">
                <div class="text" v-if="show_leftbar">{{sliceText(chat.message_last__text)}}</div>
                <div class="status" v-if="chat.count_read == 0 && show_leftbar == true">✓</div>
                <div class="status counter" v-if="chat.count_read != 0">{{ chat.count_read }}</div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="main section" ref="messagescontainer" v-if="isMobile() == false || isMobile() == true && mobile_position == 'center'">
      
        <div class="messages" ref="messages">
          <div class="message-container" ref="messagescon" :class="{notreaded: !message.read}" v-for="message in messages" :key="message">
            <div class="message" @contextmenu="contextMenu($event, message.message_id, message.text, message.id)" :class="message.echo">
              <!-- <div class="title">5927016810</div> -->
              <div class="title" v-if="message.echo=='manager'">{{ message.author }}</div>
              <div class="text-block">
                <div class="reply" v-if="message.reply != 'Не указано'" @click="mobile_context_message($event, message.message_id, message.text, message.id);">{{ message.reply }}</div>
                
                <div class="media" v-if="message.file_type == 'audio' & message.image != 'Не указан'">
                  <Fancybox
                      :options="{
                        Carousel: {
                          infinite: false,
                        },
                      }"
                  >
                    <a class="file_uploaded" data-fancybox :href="'https://dunkback.mvpproject.io/' + message.image" data-type="html5video"><span class="material-symbols-rounded">volume_up</span>Прослушать</a>
                  </Fancybox>
                </div>
                <div class="media" v-if="message.file_type == 'video' & message.image != 'Не указан'">
                  <Fancybox
                      :options="{
                        Carousel: {
                          infinite: false,
                        },
                      }"
                  >
                    <a class="file_uploaded" data-fancybox :href="'https://dunkback.mvpproject.io/' + message.image"><span class="material-symbols-rounded">smart_display</span>Посмотреть</a>
                  </Fancybox>
                </div>
                <a 
                  :href="'https://dunkback.mvpproject.io/' + message.image"
                  class="file_uploaded" target="_blank" v-if="message.file_type == 'file' & message.image != 'Не указан' | message.file_type == 'audio_file' & message.image != 'Не указан' | message.file_type == 'video_file' & message.image != 'Не указан'"
                >Файл</a>
                <div class="image" v-if="message.file_type == 'image' & message.image != 'Не указан'">
                  <Fancybox
                    :options="{
                      Carousel: {
                        infinite: false,
                      },
                    }"
                  >
                    <a class="imagelink" data-fancybox="gallery" :href="'https://dunkback.mvpproject.io/' + message.image" :class="{loadingn: message.image == 'loading'}">
                      <img :class="{loadingn: message.image == 'loading'}" :src="'https://dunkback.mvpproject.io/' + message.image" width="200" height="150" />
                    </a>
                  </Fancybox>
                </div>
                <div class="text" @click="mobile_context_message($event, message.message_id, message.text, message.id);" v-if="message.text != 'Без текста'">{{message.text}}<div v-if="message.translate != 'Не указан'"><br><br>{{message.translate}}</div></div>
                <div class="created-at" @click="mobile_context_message($event, message.message_id, message.text, message.id);">{{message.created_at}}</div>
              </div>
            </div>
          </div>
        </div>
      <div class="form">
        
        <div class="attached">
          <div class="replied" v-if="message_reply != null">
            <div class="remove material-symbols-rounded" @click="remove_reply()">close</div>
            <div class="text">
              {{ message_reply.text }}
            </div>
          </div>
          <div class="img-container" v-for="(file, index) in selectedFiles" :key="file">
            <div class="remove material-symbols-rounded" @click="remove_attach(index)">close</div>
            <img v-if="file.extension == 'image'" :src="file.file_url" alt="Выбранная картинка" />
            <video v-if="file.extension == 'video'" controls :src="file.file_url"></video>
            <audio v-if="file.extension == 'audio'" controls :src="file.file_url"></audio>
            <select v-model="file.send_type" v-if="['audio', 'video'].includes(file.extension)">
              <option value="file" selected>Файл</option>
              <option value="message">Аудио/видеосообщение</option>
            </select>
          </div>
        </div>
        <div class="image_input">
          <label for="imageInput"><span class="material-symbols-rounded">attach_file</span></label>
          <label @click="emojipicker_ex = !emojipicker_ex"><span class="material-symbols-rounded">mood</span></label>
        </div>
        <input multiple type="file" accept=".png, .jpeg, .jpg, .mp4, .ogg" id="imageInput" ref="imageInput" style="display: none;" @change="handleMultipleFileChange($event.target.files)">
        <div class="textarea">
          <EmojiPicker v-if="emojipicker_ex" :native="true" @select="addEmoji" />
          <select v-model="language">
            <option value="NO">Не переводить</option>
            <option value="ES">Испанский</option>
            <option value="PT">Португальский (Бразилия)</option>
            <option value="EN">Английский</option>
            <option value="DE">Немецкий</option>
            <option value="UZ">Узбекский</option>
          </select>
          <textarea @keyup="change_message" @keydown.enter.exact.prevent="send_message" ref="text_message_form" v-model="text_message" placeholder="Введите текст вашего сообщения" @paste="handlePaste"></textarea>
        </div>
        <button @click="send_message"><span class="material-symbols-rounded">send</span></button>
      </div>
    </div>
    <div class="right section" v-if="messenger_type == 'global' & active_chat != 0 & isMobile() == false || isMobile() == true && mobile_position == 'right'">
      <div class="top" @click="open_deal()">
        <img :src="'https://dunkback.mvpproject.io/' + active_client.avatar" alt="">
        <div class="info">
          <div class="name">{{ active_client.name }}</div>
          <div class="username">@{{ active_client.username }}</div>
        </div>
      </div>
      <div class="content">
        <ul>
          <li><span>ID игрока в системе:</span> {{active_client.id}}</li>
          <li><span>ID игрока:</span> <input type="text" :value="player_id_changed" @change="playeridUpdate(active_client.id, $event.target.value)"></li>
          <li><span>Канал:</span> {{active_client.channel}}</li>
          <li><span>Платформа:</span> {{active_client.platform}}</li>
          <li><span>Страна:</span> {{active_client.country}}</li>
          <li><span>Теги:</span> <vue3-tags-input :tags="tags" placeholder="Введите теги" @on-tags-changed="handleChangeTag"/></li>
        </ul>
      </div>
      <div class="content">
        <ul>
          <li><span>Линия:</span> {{active_client.line}}</li>
          <li style="display: flex; justify-content: space-between; gap: 4px; align-items: center;">
            <span style="word-break: normal;">Стадия:</span>
            <select v-model="stage_change" @change="change_stage">
              <option v-for="stage in stages_list" :key="stage" :value="stage.id">{{ stage.title }}</option>
            </select>
          </li>
        </ul>
      </div>
      <div class="content">
        <ul>
          <li><span>Кол-во депозитов:</span> {{active_client.deposit_count}}</li>
          <li><span>Сумма депозитов:</span> {{active_client.deposit_amount}} $</li>
          <li><span>Дата регистрации:</span> {{active_client.reg_date}}</li>
          <li><span>Дата депозитов:</span> {{active_client.dep_date}}</li>
          <li @click="copyRegister()"><span>Ссылка на регистрацию:</span> {{ active_client.reg_link }}</li>
        </ul>
      </div>
      <div class="content button" v-if="isMobile() == false" @click="show_leftbar = !show_leftbar"><i class="material-symbols-rounded">visibility</i>Левая панель</div>
    </div>
    <!-- <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  </div>
</template>
<style>
.v3ti {
  background-color: #eaeaea !important;
}
.v3ti .v3ti-new-tag {
  background: #eaeaea;
  color: black;
}

.filter > .v3ti {
  max-width: unset;
  width: calc(100% - 10px);
  font-size: .9em;
}


.filters{
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  padding-top: 0px;
}

.filters > .filter{
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.filters > .filter > input[type="text"] {
  width: calc(100% - 10px);
  padding: 5px;
  border-radius: 6px;
  background: #eaeaea;
  font-size: .8em;
}

.filters > .filter > select{
  padding: 5px;
    border-radius: 6px;
    background: #eaeaea;
    width: calc(100% - 10px);
}

.filters > .filter > label{
  color: #3c3c3c;
  font-size: .75em;
}

.filters-button{
  padding: 10px;
  background: linear-gradient(180deg, #A04B4A 0%, #3c3c3c 100%);
  border-radius: 7px;
  cursor: pointer;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.v3ti {
  background-color: unset;
  border: 0;
  max-width: 213px;
}
.v3ti .v3ti-tag {
  background: #ffffff;
  font-size: .85em;
  color: #343434;
}
.v3ti .v3ti-new-tag{
  font-size: .85em;
}
.v3ti .v3ti-tag .v3ti-remove-tag {
  color: #343434;
}


.chat > .right > .content > ul > li{
  word-break: break-word;
  font-size: .9em;
}

.chat > .right > .content > ul > li > input{
  background: unset;
}

.chat > .right > .top{
  font-size: .9em;
}

.content.button{
  font-size: .9em;
}

.chat > .right > .content > ul > li > select {
    padding: 5px;
    border-radius: 6px;
    background: #eaeaea;
    width: calc(100% - 10px);
}

.v3-emoji-picker {
    position: absolute;
    bottom: 125px;
    left: 15px;
    z-index: 99999;
}
.login-form {
    position: absolute;
    left: 0;
    top: 0;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    background: white;
    gap: 10px

}
.login-form > input{
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #e8e8e8;
}
.login-form > button{
  background: linear-gradient(180deg, #A04B4A 0%, #3c3c3c 100%);
  padding: 8px;
  color: white;
  font-weight: 600;
  border: 0;
  border-radius: 8px;
}




  #app > .menu.left{
    justify-content: flex-end;
  }

  #app > .menu.right{
    justify-content: flex-start;
    
  }



  @keyframes placeHolderShimmer {
    0% {
      background-position: -800px 0
    }
    100% {
      background-position: 800px 0
    }
  }
  .loadingn{
    position: relative;
  }
  .loadingn::after {
      animation-duration: 2s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: placeHolderShimmer;
      animation-timing-function: linear;
      background-color: #f6f7f8;
      background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
      background-size: 800px 104px;
      height: 70px;
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: 90;
      border-radius: 7px;
      content: "";
      display: flex;
  }
  input:focus {
    outline:none;
  }

  .main > .form > .attached > .img-container > video {
      max-width: 150px;
      max-height: 150px;
      border-radius: 8px;
  }



  @media screen and (max-width: 1000px){
    #app > .chat > .left{
      width: 100vw;
      min-width: 100vw;
    }
    .main {
      width: 100vw;
      min-width: 100vw;
    }
    #app > .chat > .right {
        width: calc(100vw - 45px);
        min-width: calc(100vw - 45px);
    }
  }

  .chat > .right{
    padding: 20px;
    border: 1px solid #dcdcdc;
    animation: fadeInRight;
    animation-duration: 1s;
  }

  .text > .top > .username {
      font-weight: 600;
  }
  .text > .top > .right {
      font-weight: 600;
  }


  .chat > .right > .top{
    display: flex;
    gap: 10px;
    align-items: center;
    background: linear-gradient(180deg, #A04B4A 0%, #3c3c3c 100%);
    padding: 15px;
    border-radius: 10px;
    color: white;
    cursor: pointer;
  }

  .chat > .right > .top > .info > .name{
    font-weight: 600;
  }
  .chat > .right > .top > .info > .username{
    font-weight: 500;
    font-size: .85em;
  }
  .chat > .right > .top > img{
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background: white;
  }
  .chat > .right > .content{
    margin-top: 15px;
    font-size: .85em;
    background: linear-gradient(180deg, #5d5d5d 0%, #3c3c3c 100%);
    color: white;
    padding: 15px;
    border-radius: 10px;
  }
  .chat > .right > .content li > span{
    font-weight: 600;
  }

  .chat > .left{
    width: 95px;
    min-width: 95px;
    border-right: 1px solid #dcdcdc;
  }
  .chat > .right{
    min-width: 300px;
  }

  .searchbar > input::placeholder {
    font-size: .85em;
  }

  .chat > .left, .chat > .right {
    height: 100vh;
    overflow-y: scroll;
  }
  .chat > .left.active, .chat > .right.active {
    min-width: 325px;
    height: 100vh;
    overflow-y: scroll;
  }
  .chat > .left > ul {
    display: flex;
    flex-direction: column;
  }
  .chat > .left > ul > li.active > .message-container{
    border: 0;
  }
  .chat > .left > ul > li{
    cursor: pointer;

  }
  .chat > .left > ul > li > .message-container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    /* background: #f4f4f4; */
    padding: 10px 0;
    margin: 0 20px;
    align-items: center;
    border-bottom: 1px solid #dcdcdc;
    position: relative;
  }

  @keyframes expand {
    0% {
      background-color: transparent;
    }
    100% {
      background-color: linear-gradient(180deg, #A04B4A 0%, #3c3c3c 100%);
    }
  }

  .chat > .left > ul > li.active {
    background: linear-gradient(180deg, #A04B4A 0%, #3c3c3c 100%);
    color: white;
    border: 0;
    overflow: hidden;
  }
  .chat > .left > ul > li > .message-container > .text {
    width: 100%;
  }
  .chat > .left > ul > li > .message-container .time {
    font-size: .75em;
  }
  .chat > .left > ul > li > .message-container > .text > div {
    display: flex;
    justify-content: space-between;
  }
  .chat > .left > ul > li > .message-container > img {
    min-width: 40px;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: white;
    border: 1px #c9c9c9 solid;
  }
  
  .chat{
    display: flex;
  }

  .main {
    width: 100%;
  }

  .main > .form{
    background: #f4f4f4;
    display: flex;
    padding: 8px;
    border: 0;
    gap: 8px;
    align-items: flex-start;
    position: relative;
    border-top: 1px solid #dcdcdc;
  }

  .main > .form > .image_input {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .main > .form > button, .main > .form > .image_input > label{
    background: linear-gradient(180deg, #A04B4A 0%, #3c3c3c 100%);
    display: flex;
    border: 0;
    justify-content: center;
    align-items: center;
    color: white;
    width: 35px;
    height: 35px;
    border-radius: 5px;
    cursor: pointer;
  }

  .main > .form > input{
    resize: none;
    width: 100%;
    padding: 8px;
    border-radius: 8px;
    border: 1px solid #dcdcdc;
  }

  .main > .form > .textarea {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 7px
  }

  .main > .form > .textarea > select{
    resize: none;
    /* width: 100%; */
    padding: 8px;
    border-radius: 8px;
    border: 1px solid #dcdcdc;
  }

  .main > .form > .textarea > textarea{
    resize: none;
    /* width: 100%; */
    padding: 8px;
    border-radius: 8px;
    border: 1px solid #dcdcdc;
    height: 100px;
  }

  .messages > .message-container > .manager.message {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }



  .messages > .message-container > .message{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .messages > .message-container > .message > .title{
    font-size: .85em;
  }
  .messages > .message-container > .message > .text-block > .created-at{
    font-size: .8em;
  }
  .messages > .message-container > .message > .text-block{
    padding: 10px;
    border-radius: 8px;
    font-size: .85em;
  }
  .messages > .message-container > .client.message > .text-block{
    background: #f4f4f4;
    border: 1px solid #dcdcdc;
  }
  .messages > .message-container > .manager.message > .text-block{
    background: linear-gradient(180deg, #434343 0%, #3c3c3c 100%);
    color: white;
    text-align: right;
  }


  .chat > .left > ul > li > .message-container > .text {
      font-size: .85em;
  }

  .main > .form > .attached{
    position: absolute;
    bottom: 170px;
    left: 10px;
    display: flex;
    gap: 10px;
    width: 80%;
  }

  .main > .form > .attached > .img-container, .main > .form > .attached > .replied {
    padding: 10px;
    background: #ffeae8;
    border-radius: 8px;
    border: 1px solid #dcdcdc;
    position: relative;
  }

  .main > .form > .attached > .img-container > img {
    max-width: 75px;
    max-height: 75px;
    border-radius: 8px;
  }

  .form > .attached > .img-container > .remove, .form > .attached > .replied > .remove {
      position: absolute;
      right: -12px;
      top: -12px;
      background: linear-gradient(180deg, #A04B4A 0%, #3c3c3c 100%);
      color: white;
      border-radius: 100%;
      padding: 5px;
      font-size: .95em;
      cursor: pointer;
  }

  .main>.messages {
      display: flex;
      flex-direction: column-reverse;
      height: calc(100% - 159px);
      overflow-y: scroll;
  }

  .message-container{
    padding: 10px;
  }
  .message-container.notreaded{
    background: rgba(43, 43, 43, 0.032);
  }

  .main > .messages > .message-container:last-child > .message{
    margin-bottom: 10px;
  }

  .chat{
    height: 100vh;
    overflow-y: hidden;
  }

  .chatmobile{
    height: calc(100vh - 65px);
  }

  .messages > .message-container > .message video, .messages > .message-container > .message > audio{
    max-width: 160px;
    max-height: 160px;
  }
  .messages > .message-container > .message a.file_uploaded{
    display: flex;
    padding: 7px;
    border-radius: 5px;
    color: #d6d6d6;
    margin-bottom: 5px;
    background: #454545;
    text-align: center;
  }
  
  .messages > .message-container > .manager.message > .title{
    text-align: right;
  }

  .message fancybox > a.imagelink {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
  }

  .message fancybox:hover > a.imagelink::after {
    content: "Открыть";
    position: absolute;
    background: #262626cc;
    height: 100%;
    color: white;
    border-radius: 8px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: .85em;
  }

  .message img {
    max-width: 150px;
    max-height: 350px;
    object-fit: cover;
    border-radius: 8px;
    background: white;
  }

  .message > .text-block > .reply{
    background: #383838;
    color: #ffffff;
    border: 1px solid #5f5f5f;
    border-radius: 8px;
    padding: 8px;
    margin-bottom: 8px;
  }

  .material-symbols-rounded {
    font-variation-settings:
    'FILL' 1,
    'wght' 400,
    'GRAD' 200,
    'opsz' 40
  }
  .searchbar {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    padding: 15px;
  }
  .searchbar > input{
    width: 100%;
    border-radius: 7px;
    background: #F5F6F6;
    padding: 15px;
    border: 0;
    font-size: .75em;
  }
  i.reply::after {
      content: "reply";
  }
  i.visibility::after {
      content: "visibility";
  }
  i.translate::after {
      content: "translate";
  }
  i.content_copy::after {
      content: "content_copy";
  }
  .chat > .right > .content > i {
    font-size: .9em;
}
.content.button{
  display: flex;
  align-items: center;
  gap: 9px;
  font-weight: 500;
  cursor: pointer;
}
::-webkit-scrollbar {
    width: 15px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #000000a3;
    border-radius: 20px;
    border: 4px solid transparent;
    background-clip: content-box;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: #1d1d1d9f;
  }

  

  .status.counter{
    padding: 5px;
    background: #3c3c3c;
    color: white;
    font-weight: 500;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0;
    bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .75em;
  }


  #app > .menu {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    margin-bottom: 10px;
    background: #feeae8;
}









li.active .status.counter{
    background: white;
    color: #3c3c3c;
  }


  .chat.green .filters > .filter > label{
    color: #3c3c3c;
    font-size: .75em;
  }
  
  .chat.green .filters-button{
    padding: 10px;
    background: linear-gradient(180deg, #E1FFC5 0%, #E1FFC5 100%);
    border-radius: 7px;
    cursor: pointer;
    border: 1px solid #23232321;
    color: #2e2e2e;
    display: flex;
    justify-content: center;
    align-items: center;
  }

.chat.green .main > .form > button, .chat.green .main > .form > .image_input > label{
  background: linear-gradient(180deg, #E1FFC5 0%, #E1FFC5 100%);
  border: 1px solid #23232321;
  color: #2e2e2e;
}

.chat.green .form > .attached > .img-container > .remove, .chat.green .form > .attached > .replied > .remove {
  background: linear-gradient(180deg, #E1FFC5 0%, #E1FFC5 100%);
  border: 1px solid #23232321;
  color: #2e2e2e;
}

.chat.green .login-form > button{
  background: linear-gradient(180deg, #E1FFC5 0%, #E1FFC5 100%);
  border: 1px solid #23232321;
  color: #2e2e2e;
}

.chat.green > .right > .top{
  background: linear-gradient(180deg, #E1FFC5 0%, #E1FFC5 100%);
  border: 1px solid #23232321;
  color: #2e2e2e;
}

.chat.green > .left > ul > li.active {
  background: linear-gradient(180deg, #E1FFC5 0%, #E1FFC5 100%);
  border-top: 1px solid #23232321;
  border-bottom: 1px solid #23232321;
  color: #2e2e2e;
}

.chat.green .form > .attached > .img-container > .remove, .chat.green .form > .attached > .replied > .remove {
  background: linear-gradient(180deg, #E1FFC5 0%, #E1FFC5 100%);
  border: 1px solid #23232321;
  color: #2e2e2e;
}

.chat.green .messages > .message-container > .manager.message > .text-block{
  background: linear-gradient(180deg, #E1FFC5 0%, #E1FFC5 100%);
  border: 1px solid #23232321;
  color: #2e2e2e;
  text-align: right;
}

.chat.green > .right > .content{
  margin-top: 15px;
  font-size: .85em;
  background: linear-gradient(180deg, #E1FFC5 0%, #E1FFC5 100%);
  border: 1px solid #23232321;
  color: #2e2e2e;
  padding: 15px;
  border-radius: 10px;
}

.chat.green .status.counter{
  background: #99c7ab;
  color: #2e2e2e;
}

.chat.green li.active > .status.counter{
  background: #99c7ab;
  color: #2e2e2e;
}

#app > .menu.green{
  background: #99c7ab;
  border: 1px solid #23232321;
}

.chat.green .main > .messages{
  background: url("../assets/messages_pattern.png"), #88d095;
  background-size: 100px;
}







.chat.blue .filters > .filter > label{
  color: #3c3c3c;
  font-size: .75em;
}

.chat.blue .filters-button{
  padding: 10px;
  background: linear-gradient(180deg, #238ef3 0%, #238ef3 100%);
  border-radius: 7px;
  cursor: pointer;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat.blue .manager.message > .text-block > .reply{
    background: #f4f4f4;
    border: 1px solid #dcdcdc;
    background-image: url('../assets/pattern.png');
    color: black;
    border: 1px solid #26262624;
}

.message-container.notreaded{
  background: rgba(156, 212, 255, 0.098);
}

.chat.blue .main > .form > button, .chat.blue .main > .form > .image_input > label{
  background: linear-gradient(180deg, #238ef3 0%, #238ef3 100%);
  color: white;
}

.chat.blue .form > .attached > .img-container > .remove, .chat.blue .form > .attached > .replied > .remove {
  background: linear-gradient(180deg, #238ef3 0%, #238ef3 100%);
  color: white;
}

.chat.blue .login-form > button{
  background: linear-gradient(180deg, #238ef3 0%, #238ef3 100%);
  color: #ffffff;
}

.chat.blue > .right > .top{
  background: linear-gradient(180deg, #238ef3 0%, #238ef3 100%);
  color: #ffffff;
}

.chat.blue > .left > ul > li.active {
  background: linear-gradient(180deg, #238ef3 0%, #238ef3 100%);
  color: white;
}

.chat.blue .form > .attached > .img-container > .remove, .chat.blue .form > .attached > .replied > .remove {
  background: linear-gradient(180deg, #238ef3 0%, #238ef3 100%);
  color: white;
}

.chat.blue .messages > .message-container > .manager.message > .text-block{
  background: linear-gradient(180deg, #238ef3 0%, #238ef3 100%);
  color: white;
  text-align: right;
  border: 1.3px solid #00000015;
}

.chat.blue > .right > .content{
  margin-top: 15px;
  font-size: .85em;
  background: linear-gradient(180deg, #238ef3 0%, #238ef3 100%);
  color: white;
  padding: 15px;
  border-radius: 10px;
}

.chat.blue .status.counter{
  background: #3d8eda;
  color: white;
}

.chat.blue li.active .status.counter{
  background: #f3f9ff;
  color: #3d8eda;
}

.chat.blue .main > .form > .attached > .img-container, .chat.blue .main > .form > .attached > .replied {
  background: #7e9fbf;
  border: 0;
  font-size: .9em;
  color: white;
}

.chat.blue .main > .messages{
  background: url("../assets/messages_pattern.png"), #a1d9ff;
  background-size: 100px;
}


#app > .menu.blue{
  background: #7e9fbf;
}






.chat.dark .filters > .filter > label{
  color: white;
  font-size: .75em;
}

.chat.dark .filters-button{
  padding: 10px;
  background: linear-gradient(180deg, #323232 0%, #323232 100%);
  border-radius: 7px;
  cursor: pointer;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat.dark .main > .form > button, .chat.dark .main > .form > .image_input > label{
  background: linear-gradient(180deg, #323232 0%, #323232 100%);
  border: 1px solid #23232321;
  color: rgb(194, 194, 194);
}

.chat.dark .form > .attached > .img-container > .remove, .chat.dark .form > .attached > .replied > .remove {
  background: linear-gradient(180deg, #323232 0%, #323232 100%);
  border: 1px solid #23232321;
  color: rgb(194, 194, 194);
}

.chat.dark .login-form > button{
  background: linear-gradient(180deg, #323232 0%, #323232 100%);
  border: 1px solid #23232321;
  color: rgb(194, 194, 194);
}

.chat.dark > .right > .top{
  background: linear-gradient(180deg, #323232 0%, #323232 100%);
  border-left: 1px solid #23232321;
  color: rgb(194, 194, 194);
}

.chat.dark > .right{
  border: 0;
  border-left: 1px solid #23232321;
}

.chat.dark .main > .form{
  background: linear-gradient(180deg, #323232 0%, #323232 100%);
  border: 0;
}

.chat.dark .main > .form > .textarea > textarea, .chat.dark .main > .form > .textarea > select{
  background: #323232;
  color: rgb(194, 194, 194);
  border: 1px solid rgb(68, 68, 68);
}

.chat.dark > .left > ul > li.active {
  background: linear-gradient(180deg, #323232 0%, #323232 100%);
  border-top: 1px solid #2b2b2b;
  border-bottom: 1px solid #2b2b2b;
  color: rgb(194, 194, 194);
}

.chat.dark .searchbar > input{
  background: #323232;
  color: rgb(194, 194, 194);
}

.chat.dark > .right{
  background: #232323;
}

.chat.dark .messages > .message-container > .message.client > .text-block{
  background: #131313;
  border: 1px solid #242424;
  color: rgb(194, 194, 194);
}

.chat.dark .form > .attached > .img-container > .remove, .chat.dark .form > .attached > .replied > .remove {
  background: linear-gradient(180deg, #323232 0%, #323232 100%);
  border: 1px solid #23232321;
  color: rgb(194, 194, 194);
}

.chat.dark .messages > .message-container > .manager.message > .text-block{
  background: linear-gradient(180deg, #323232 0%, #323232 100%);
  border: 1px solid #23232321;
  color: rgb(194, 194, 194);
  text-align: right;
}

.chat.dark > .right > .content{
  margin-top: 15px;
  font-size: .85em;
  background: linear-gradient(180deg, #323232 0%, #323232 100%);
  border: 1px solid #23232321;
  color: rgb(194, 194, 194);
  padding: 15px;
  border-radius: 10px;
}

.chat.dark .status.counter{
  background: #323232;
  color: rgb(194, 194, 194);
}

.chat.dark li.active > .status.counter{
  background: #323232;
  color: rgb(194, 194, 194);
}

#app > .menu.dark{
  background: #323232;
  border: 1px solid #23232321;
}

.chat.dark .main > .messages{
  background: url("../assets/messages_pattern.png"), #1e1e1e;
  background-size: 100px;
}

.chat.dark > .left{
  background: #232323;
  border-right: 1px solid #2b2b2b;
}

.chat.dark > .left > ul > li > .message-container > .text{
  color: rgb(194, 194, 194);
}

.chat.dark > .left > ul > li > .message-container{
  border-bottom: 1px solid #2b2b2b;
}

.chat.dark .manager.message > .title{
  color: rgb(192, 192, 192);
}




li > .text > .bottom > .text {
    color: #4C4D4D;
}

</style>
<script>
import 'animate.css';
// import EmojiPicker from 'vue3-emoji-picker';
import 'vue3-emoji-picker/css';
// import 'selectize/dist/css/selectize.default.css';
// import VSelectize from '@isneezy/vue-selectize';
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import EmojiPicker from 'vue3-emoji-picker'
import axios from 'axios';
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import ContextMenu from '@imengyu/vue3-context-menu';
import Vue3TagsInput from 'vue3-tags-input';

export default {
  name: 'HomeView',
  metaInfo: () => ({
    meta: [
      {
        title: 'Dunk',
        viewport: 'width=device-width, initial-scale=1, maximum-scale=1,user-scalable=no'
      }
    ],
  }),
  components: {
    EmojiPicker,
    Vue3TagsInput
    // VSelectize
  },
  props: {
    options: Object,
  },
  data() {
    return {
      text_message: "",
      messages: [],
      player_id_changed: "",
      filters_channels: [],
      filters_platforms: [],
      filters_responsible: [],
      tags_filter: [],
      tags_filter_string: [],
      alerts: [
        {
          text: "Пользователь XXX зарегистрировался на платформе"
        },
        {
          text: "Пользователь XYX зарегистрировался на платформе"
        },
        {
          text: "Пользователь XYZ зарегистрировался на платформе"
        }
      ],
      active_client: {
        id: 0,
        name: "Не указан",
        username: "Не указан",
        reg_link: ""
      },
      filter_platform_value: "",
      filter_channel_value: "",
      filter_responsible_value: "",
      filter_stage_value: "",
      stage_change: 0,
      stages_list: [
            {
              title: 'Не обработано',
              id: 1,
              standart: true,
              ignore: false,
              robots: [
                {
                  if_condition: 'Пришло сообщение',
                  timer: 0,
                  then_condition: 'Перевести на стадию',
                  line_to_id: 'Ведём общение'
                }
              ]
            },
            {
              title: 'Ведём общение',
              id: 2,
              standart: false,
              ignore: false,
              robots: [
              ]
            },
            {
              title: 'Отправлена ссылка',
              id: 3,
              standart: false,
              ignore: false,
              robots: [
                {
                  if_condition: 'Сделана регистрация',
                  timer: 0,
                  then_condition: 'Перевести на стадию',
                  line_to_id: 'Сделана регистрация'
                }
              ]
            },
            {
              title: 'Сделана регистрация',
              id: 4,
              standart: false,
              ignore: false,
              robots: [
                {
                  if_condition: 'Сделан депозит',
                  timer: 0,
                  then_condition: 'Перевести на стадию',
                  line_to_id: 'Сделана регистрация'
                }
              ]
            },
            {
              title: 'Вернулся из игнора',
              id: 5,
              standart: false,
              ignore: false,
              robots: []
            },
            {
              title: 'Игнор',
              id: 6,
              standart: false,
              ignore: false,
              robots: [
                {
                  if_condition: 'Пришло сообщение от клиента',
                  timer: 0,
                  then_condition: 'Перевести на стадию',
                  line_to_id: 'Вернулся из игнора'
                }
              ]
            },
            {
              title: 'Сделал 1-й деп',
              id: 7,
              standart: false,
              ignore: false,
              robots: []
            },
            {
              title: 'Переведен с депом',
              id: 8,
              standart: false,
              ignore: false,
              robots: [
                {
                  if_condition: 'Пришло сообщение',
                  timer: 0,
                  then_condition: 'Перевести на стадию',
                  line_to_id: 'Ведём общение'
                }
              ]
            },
            {
              title: 'Ведем общение',
              id: 9,
              standart: false,
              ignore: false,
              robots: [
                {
                  if_condition: 'Сделал редеп',
                  timer: 0,
                  then_condition: 'Перевести на стадию',
                  line_to_id: 'Сделал редеп'
                }
              ]
            },
            {
              title: 'Вернулся из игнора',
              id: 10,
              standart: false,
              ignore: false,
              robots: []
            },
            {
              title: 'Игнор',
              id: 11,
              standart: false,
              ignore: false,
              robots: [
                {
                  if_condition: 'Пришло сообщение от клиента',
                  timer: 0,
                  then_condition: 'Перевести на стадию',
                  line_to_id: 'Вернулся из игнора'
                }
              ]
            },
            {
              title: 'Сделал редеп',
              id: 12,
              standart: false,
              ignore: false,
              robots: []
            }
      ],
      selectedFiles: [],
      active_chat: 0,
      chats: [],
      send_type: null,
      filtered_chats: [],
      messenger_type: "global",
      search: "",
      tokeninput: "",
      message_filter: "",
      updater: setInterval(async () => {this.update_messages();}, 15000),
      updater_chat: setInterval(async () => {this.update_chat();}, 5000),
      message_reply: null,
      token: null,
      language: "NO",
      max_pages: 1,
      selectedFileURL: null,
      show_context: false,
      show_leftbar: true,
      theme: "blue",
      fileExtension: "",
      emojipicker_ex: false,
      on_chats_update: false,
      filter_timer: null,
      mobile_position: "left",
      tags: [],
      filters_opened: false,
      drafts: {}
    }
  },
  mounted() {
    var $this = this;
    // window.addEventListener('keyup', function(event) {
    //   if (event.key === 'Enter') { 
    //     $this.send_message(event);
    //   }
    // });
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('type')){
      this.messenger_type = urlParams.get('type');
    }
    if (urlParams.has('chat')){
      this.active_chat = urlParams.get('chat');
    }
    if (urlParams.has('theme')){
      this.theme = urlParams.get('theme');
    }
    if (urlParams.has('token')){
      this.token = urlParams.get('token');
    }
    if (this.$route.params.token != ""){
      this.token = this.$route.params.token;
    }
    if (this.token != null & this.token != localStorage.getItem('token')){
      localStorage.token = this.token;
    }
    if (localStorage.getItem('token') !== null) {
      this.token = localStorage.token;
    }
    else if (this.token != null){
      localStorage.token = this.token;
      console.log(localStorage.token);
    }

    axios.get('https://mvpproject.io/theme/get/?token=' + this.token).then(function(res) {
        $this.theme = res.data.theme;
    });

    axios.get('https://mvpproject.io/api/stages/get/?token=' + this.token).then(function(res) {
        $this.stages_list = res.data;
    });

    axios.get('https://mvpproject.io/api/filters/get/?token=' + this.token).then(function(res) {
        $this.filters_channels = res.data.channels;
        $this.filters_platforms = res.data.platforms;
        $this.filters_responsible = res.data.responsible;
    });

    this.update_messages();
  },
  unmounted() {
    Fancybox.destroy();
    this.stopUpdater();
  },
  methods: {
    isImageUrl(url) {
      // Проверка наличия расширения изображения
      return url.match(/\.(jpeg|jpg|gif|png|mp4|MP4|ogg|OGG)$/) != null;
    },
    handleChangeTagFilter(tags) {
      this.tags_filter = tags;
      this.tags_filter_string = "";
      var $this = this;
      this.tags_filter.forEach(function(tag, idx, array) {
        $this.tags_filter_string += tag;
        if (idx !== array.length - 1){ 
          $this.tags_filter_string += ",";
        }
      });
      this.onFilter();
    },
    handleChangeTag(tags) {
      this.tags = tags;
      const formData = new FormData();
      var tags_string = "";
      this.tags.forEach(function(tag, idx, array) {
        tags_string += tag;
        if (idx !== array.length - 1){ 
          tags_string += ",";
        }
      });
      var $this = this;
      formData.append('tags', tags_string);
      formData.append('token', this.token);
      formData.append('id', this.active_client.id);
      axios.post('https://mvpproject.io/api/player/change/tags/', formData).then(
        function(res) {
          $this.tags = res.data;
          $this.active_client.tags = res.data;
        }
      );
      console.log(tags_string);
    },
    playeridUpdate(playerid, text){
      this.player_id_changed = text;
      var params = {
        token: this.token,
        id: playerid,
        playerid: text
      };
      axios.get('https://mvpproject.io/business/api/update/player/id/', {params});
    },
    change_message() {
      if (this.active_chat != 0){
        if (this.text_message != ""){
          this.drafts[this.active_chat] = this.text_message;
          var draft_temp_chats = []
          var temp_chats = [];
          this.chats.forEach(chat => {
            if (chat.id in this.drafts){
              var ch = chat;
              ch.message_last__text = "Черновик: " + this.drafts[chat.id.toString()].substring(0, 15);
              var now = new Date();
              ch.message_last__created_at = new Date(now.getTime() + (3 * 60 * 60 * 1000)).toISOString();
              draft_temp_chats.push(ch);
            }
            else{
              temp_chats.push(chat);
            }
          });
          draft_temp_chats.forEach(chat => {
            temp_chats.unshift(chat);
          });
          this.filtered_chats = temp_chats;
        }
        else{
          delete this.drafts[this.active_chat];
        }
      }
    },
    getFromDrafts() {
      if (this.active_chat in this.drafts){
        this.text_message = this.drafts[this.active_chat];
      }
      else{
        this.text_message = "";
      }
    },
    setEmoji (emoji) {
      console.log(emoji)
    },
    infinity(e) {
      if (this.on_chats_update == false){
        var listElm = e.target;
        if(listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight - 150) {
          this.max_pages += 1;
          this.update_messages();
        }
      }
    },
    mobile_context_message(e, message_id, text, mid) {
      if (this.isMobile()){
        e.preventDefault();
        this.contextMenu(e, message_id, text, mid);
      }
    },
    tokenset() {
      localStorage.token = this.tokeninput;
      this.token = this.tokeninput;
      var $this = this;
      axios.get('https://mvpproject.io/theme/get/?token=' + this.token).then(function(res) {
        $this.theme = res.data.theme;
      });
    },
    calculateSectionOffsets() {
      let sections = document.getElementsByTagName('section');
      let length = sections.length;
      
      for(let i = 0; i < length; i++) {
        let sectionOffset = sections[i].offsetTop;
        this.offsets.push(sectionOffset);
      }
    },
    /**
     * Handle the 'mousewheel' event for other browsers
     */
    handleMouseWheel: function(e) {
      
      if (e.wheelDelta < 30 && !this.inMove) {
        this.moveUp();
      } else if (e.wheelDelta > 30 && !this.inMove) {
        this.moveDown();
      }
        
      e.preventDefault();
      return false;
    },
    /**
     * Handle the 'DOMMouseScroll' event for Firefox
     */
    handleMouseWheelDOM: function(e) {
      
      if (e.detail > 0 && !this.inMove) {
        this.moveUp();
      } else if (e.detail < 0 && !this.inMove) {
        this.moveDown();
      }
      
      return false;
    },
    /**
     * Move to the previous section or the last section if you're on the first section
     */
    moveDown() {
      this.inMove = true;
      this.activeSection--;
        
      if(this.activeSection < 0) this.activeSection = this.offsets.length - 1;
        
      this.scrollToSection(this.activeSection, true);
    },
    /**
     * Move to the next section or the first section if you're on the last section
     */
    moveUp() {
      this.inMove = true;
      this.activeSection++;
        
      if(this.activeSection > this.offsets.length - 1) this.activeSection = 0;
        
      this.scrollToSection(this.activeSection, true);
    },
    /**
     * Scrolls to the passed section id if the section exists and the delay is over
     */
    scrollToSection(id, force = false) {
      if(this.inMove && !force) return false;
      
      this.activeSection = id;
      this.inMove = true;
      
      // get section and scroll into view if it exists
      let section = document.getElementsByTagName('section')[id];
      if(section) {
        document.getElementsByTagName('section')[id].scrollIntoView({behavior: 'smooth'});
      }
      
      setTimeout(() => {
        this.inMove = false;
      }, this.inMoveDelay);
      
    },
    /**
     * Handles the 'touchstart' event on mobile devices
     */
    touchStart(e) {
      e.preventDefault();
      
      this.touchStartY = e.touches[0].clientY;
    },
    /**
     * Handles the 'touchmove' event on mobile devices
     */
    addEmoji(emoji) {
      this.text_message += emoji.i;
    },

    touchMove(e) {
      if(this.inMove) return false;
      e.preventDefault();
      
      const currentY = e.touches[0].clientY;
      
      if(this.touchStartY < currentY) {
        this.moveDown();
      } else {
        this.moveUp();
      }
      
      this.touchStartY = 0;
      return false;
    },




    send_message() {
      clearInterval(this.updater_chat);
      var image_sended = false;
      var text_message_to_send = this.text_message;
      this.selectedFiles.forEach((file, index) => {
        var formData = new FormData();
        image_sended = true;
        formData.append('file', file.file);
        if (file.extension != null){
          if (file.extension == "video" & this.send_type == "file" | file.extension == "audio" & this.send_type == "file"){
            if (file.extension == "video"){
              formData.append('file_type', "video_file");
            }
            if (file.extension == "audio"){
              formData.append('file_type', "audio_file");
            }
          }
          else{
            formData.append('file_type', file.extension);
          }
        }
        else{
          formData.append('file_type', "image");
        }
        this.messages.unshift(
          {
              "id": 0,
              "text": "Без текста",
              "echo": "manager",
              "created_at": "Сообщение отправляется",
              "image": "loading",
              "message_id": 0,
              "reply": this.message_reply != null ? this.message_reply.text : "Не указано",
              "read": true
          }
        );
        formData.append('chat_id', this.active_chat);
        formData.append('text', "");
        formData.append('language', this.language);
        formData.append('token', this.token);
        if (this.message_reply != null){
          formData.append('reply', this.message_reply.id);
          formData.append('reply_text', this.message_reply.text);
        }
        var $this = this;
        $this.text_message = "";
        axios.post('https://dunkback.mvpproject.io/message/send/', formData,
        {
            headers:{
              'Content-Type': 'multipart/form-data'
            }
        }).then(function(res) {
          if (index+1 == $this.selectedFiles.length & text_message_to_send == ""){
            text_message_to_send = "";
            $this.fileExtension = "";
            $this.send_type = null;
            if ($this.active_chat in $this.drafts){
              $this.drafts[$this.active_chat] = "";
            }
            delete $this.drafts[$this.active_chat];
            $this.message_reply = null;
            $this.selectedFiles = [];
            $this.selectedFileURL = null;
            $this.$refs.imageInput.value = null;
            $this.update_chat();
            // if (res.data.status != "done"){
            //   $this.$notify({type: "error", text: res.data.status});
            // }
            console.log(res.data.status);
            var draft_temp_chats = [];
            var temp_chats = [];
            $this.chats.forEach(chat => {
              if (chat.id in $this.drafts){
                var ch = chat;
                ch.message_last__text = "Черновик: " + $this.drafts[chat.id.toString()].substring(0, 15);
                ch.message_last__created_at = new Date().toISOString();
                draft_temp_chats.push(ch);
              }
              else{
                temp_chats.push(chat);
              }
            });
            draft_temp_chats.forEach(chat => {
              temp_chats.unshift(chat);
            });
            $this.filtered_chats = temp_chats;
            $this.updater_chat = setInterval(async () => {$this.update_chat();}, 5000);
          }
          else if (index+1 == $this.selectedFiles.length & text_message_to_send != ""){
            var formData = new FormData();
            formData.append('chat_id', $this.active_chat);
      formData.append('text', text_message_to_send);
      formData.append('language', $this.language);
      formData.append('token', $this.token);
      if (text_message_to_send != ""){
          $this.messages.unshift(
            {
                "id": 0,
                "text": text_message_to_send,
                "echo": "manager",
                "created_at": "Сообщение отправляется",
                "image": "Не указан",
                "message_id": 0,
                "reply": $this.message_reply != null ? $this.message_reply.text : "Не указано",
                "read": true
            }
          )
        }
        $this.messages.forEach(message => {
          message.read = true;
        });
        if ($this.message_reply != null){
          formData.append('reply', $this.message_reply.id);
          formData.append('reply_text', $this.message_reply.text);
        }
        // var $this = this;
        $this.text_message = "";
        axios.post('https://dunkback.mvpproject.io/message/send/', formData,
        {
            headers:{
              'Content-Type': 'multipart/form-data'
            }
        }).then(function(res) {
          text_message_to_send = "";
          $this.fileExtension = "";
          $this.send_type = null;
          if ($this.active_chat in $this.drafts){
            $this.drafts[$this.active_chat] = "";
          }
          delete $this.drafts[$this.active_chat];
          $this.message_reply = null;
          $this.selectedFiles = [];
          $this.selectedFileURL = null;
          $this.$refs.imageInput.value = null;
          $this.update_chat();
          // if (res.data.status != "done"){
          //   $this.$notify({type: "error", text: res.data.status});
          // }
          console.log(res.data.status);
          var draft_temp_chats = [];
            var temp_chats = [];
            $this.chats.forEach(chat => {
              if (chat.id in $this.drafts){
                var ch = chat;
                ch.message_last__text = "Черновик: " + $this.drafts[chat.id.toString()].substring(0, 15);
                ch.message_last__created_at = new Date().toISOString();
                draft_temp_chats.push(ch);
              }
              else{
                temp_chats.push(chat);
              }
            });
            draft_temp_chats.forEach(chat => {
              temp_chats.unshift(chat);
            });
            $this.filtered_chats = temp_chats;
            $this.updater_chat = setInterval(async () => {$this.update_chat();}, 5000);
        });
          }
        });
      });

      var formData = new FormData();

      this.chats.forEach(chat => {
        if (chat.id == this.active_chat){
          if (this.text_message != ""){
            chat.message_last__text = this.text_message.slice(0, 15);
            chat.count_read = 0;
          }
          else{
            chat.message_last__text = "Без текста";
            chat.count_read = 0;
          }
        }
      });

      if (this.text_message != "" & image_sended == false){
      formData.append('chat_id', this.active_chat);
      formData.append('text', this.text_message);
      formData.append('language', this.language);
      formData.append('token', this.token);
      if (this.text_message != ""){
          this.messages.unshift(
            {
                "id": 0,
                "text": this.text_message,
                "echo": "manager",
                "created_at": "Сообщение отправляется",
                "image": "Не указан",
                "message_id": 0,
                "reply": this.message_reply != null ? this.message_reply.text : "Не указано",
                "read": true
            }
          )
        }
        this.messages.forEach(message => {
          message.read = true;
        });
        if (this.message_reply != null){
          formData.append('reply', this.message_reply.id);
          formData.append('reply_text', this.message_reply.text);
        }
        var $this = this;
        $this.text_message = "";
        axios.post('https://dunkback.mvpproject.io/message/send/', formData,
        {
            headers:{
              'Content-Type': 'multipart/form-data'
            }
        }).then(function(res) {
          text_message_to_send = "";
          $this.fileExtension = "";
          $this.send_type = null;
          if ($this.active_chat in $this.drafts){
            $this.drafts[$this.active_chat] = "";
          }
          delete $this.drafts[$this.active_chat];
          $this.message_reply = null;
          $this.selectedFiles = [];
          $this.selectedFileURL = null;
          $this.$refs.imageInput.value = null;
          $this.update_chat();
          // if (res.data.status != "done"){
          //   $this.$notify({type: "error", text: res.data.status});
          // }
          console.log(res.data.status);
          var draft_temp_chats = [];
            var temp_chats = [];
            $this.chats.forEach(chat => {
              if (chat.id in $this.drafts){
                var ch = chat;
                ch.message_last__text = "Черновик: " + $this.drafts[chat.id.toString()].substring(0, 15);
                ch.message_last__created_at = new Date().toISOString();
                draft_temp_chats.push(ch);
              }
              else{
                temp_chats.push(chat);
              }
            });
            draft_temp_chats.forEach(chat => {
              temp_chats.unshift(chat);
            });
            $this.filtered_chats = temp_chats;
            $this.updater_chat = setInterval(async () => {$this.update_chat();}, 5000);
        });
      }

    },
    scroll_to_bottom: function () {
      // this.$refs.messages_scroll.scrollIntoView({ behavior: "smooth" });
    },
    scroll_handler: function () {
    },
    
    remove_attach: function (index) {
      // this.selectedFileURL = null;
      // this.selectedFiles = null;
      // this.fileExtension = null;
      // this.send_type = null;
      this.selectedFiles.splice(index, 1);
      // this.$refs.imageInput.value = null;
    },
    remove_reply: function () {
      this.message_reply = null;
    },
    handleFileChange(file) {
      if (this.selectedFiles.length < 4){
        var file_structure = {
          file: file,
          extension: "image",
          file_url: "",
          send_type: "file"
        }
        file_structure.extension = file.name.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2);
        if (["png", "jpg", "jpeg"].includes(file_structure.extension)){
          file_structure.extension = "image";
        }
        if (["mp4", "MP4", "Mp4", "mP4"].includes(file_structure.extension)){
          file_structure.extension = "video";
        }
        if (["ogg", "OGG", "oGG", "ogG", "oGg"].includes(file_structure.extension)){
          file_structure.extension = "audio";
        }
        var reader = new FileReader();

        reader.onload = (e) => {
          file_structure.file_url = e.target.result;
        };
        reader.readAsDataURL(file);

        this.selectedFiles.push(file_structure);
        console.log(this.selectedFiles);
      }
      else{
        this.$notify({type: "info", text: "Нельзя добавить больше 3 прикрепленных файлов"});
      }
    },
    handleMultipleFileChange(files) {
      files.forEach(file => {
        this.handleFileChange(file);
      });
    },
    sliceText: function (text) {
      if (text != null){
        return text.slice(0, 15);
      }
      else{
        return "Не указано"
      }
    },
    sliceText10: function (text) {
      if (text != null){
        return text.slice(0, 10);
      }
      else{
        return "Не указано"
      }
    },
    formatMoscowTime: function (isoDate) {
      const date = new Date(isoDate);
      date.setHours(date.getHours() - 3);
      const options = {
        timeZone: 'Europe/Moscow',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      };
      return date.toLocaleString('ru-RU', options);
    },
    change_stage: function () {
      axios.get(
        'https://mvpproject.io/crm/ex/api/player/stage/change/' + this.stage_change + "/" + this.active_chat + "/",
      )
    },
    handlePaste(event) {
      const clipboardData = event.clipboardData || window.clipboardData;
      const items = clipboardData.items;

      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf('image') === 0) {
          const file = items[i].getAsFile();

          const dataTransfer = new DataTransfer();
          dataTransfer.items.add(file);

          this.$refs.imageInput.files = dataTransfer.files;
          this.handleFileChange(this.$refs.imageInput.files[0]);
        }
      }
    },
    update_messages: function () {
      if (this.messenger_type != "global"){
        return;
      }
      this.on_chats_update = true;
      if (this.token == null){
        return
      }

      // Fancybox.unbind(this.$refs.container);
      // // Fancybox.close();

      // Fancybox.bind(this.$refs.container, '[data-fancybox]', {
      //   ...(this.options || {}),
      // });
      var $this = this;
      axios.get('https://mvpproject.io/new/message/get/' , {params: { stage: this.filter_stage_value, platform: this.filter_platform_value, responsible: this.filter_responsible_value, channels: this.filter_channel_value, client_id: this.active_chat, token: this.token, max_page: this.max_pages, search: this.search, message_filter: this.message_filter, tags: this.tags_filter_string }}).then(function(res) {
        // console.log(res.data.messages);
        // $this.messages = res.data.messages;
        // $this.active_client.id = res.data.client.id;
        // $this.active_client.username = res.data.client.username;
        // $this.active_client.name = res.data.client.name;
        // $this.active_client.avatar = res.data.client.avatar;
        // $this.active_client.deposit_count = res.data.client.deposit_count;
        // $this.active_client.deposit_amount = res.data.client.deposit_amount;
        // $this.active_client.country = res.data.client.country;
        // $this.active_client.platform = res.data.client.platform;
        // $this.active_client.link_to_deal = res.data.client.link_to_deal;
        // if (this.active_chat in this.drafts){
        //   this.text_message = this.drafts[this.active_chat];
        // }
        // else{
        //   this.text_message = "";
        // }
        $this.alerts = res.data.alerts;
        $this.alerts.forEach(alert => {
          $this.$notify({type: "info", text: alert.text});
        });
        $this.alerts = [];
        var draft_temp_chats = []
        var temp_chats = [];
        res.data.clients.forEach(chat => {
          if (chat.id in $this.drafts){
            var ch = chat;
            ch.message_last__text = "Черновик: " + $this.drafts[chat.id.toString()].substring(0, 15);
            ch.message_last__created_at = new Date().toISOString();
            draft_temp_chats.push(ch);
          }
          else{
            temp_chats.push(chat);
          }
        });
        draft_temp_chats.forEach(chat => {
          temp_chats.unshift(chat);
        });
        $this.chats = temp_chats;
        $this.filtered_chats = $this.chats;
        $this.onFilter();
        $this.on_chats_update = false;
      })
    },
    countObjectsWithTrueKey: function (array) {
      let count = 0;
      for (let i = 0; i < array.length; i++) {
        if (array[i].read === false) {
          count++;
        }
      }
      return count;
    },
    update_chat: function () {
      if (this.token == null){
        return
      }
      Fancybox.unbind(this.$refs.container);
      // Fancybox.close();

      Fancybox.bind(this.$refs.container, '[data-fancybox]', {
        ...(this.options || {}),
      });
      var $this = this;
      axios.get('https://mvpproject.io/chat/get/?client_id=' + this.active_chat + '&token=' + this.token).then(function(res) {
        console.log(res.data.messages);
        $this.messages = res.data.messages;
        $this.active_client.id = res.data.id;
        $this.active_client.username = res.data.username;
        $this.active_client.name = res.data.name;
        $this.active_client.avatar = res.data.avatar;
        $this.active_client.deposit_count = res.data.deposit_count;
        $this.active_client.deposit_amount = res.data.deposit_amount;
        $this.active_client.country = res.data.country;
        $this.active_client.playerid = res.data.player_id;
        $this.active_client.tags = res.data.tags;

        $this.active_client.reg_date = res.data.reg_date;
        $this.active_client.dep_date = res.data.dep_date;
        $this.active_client.line = res.data.line;
        $this.active_client.channel = res.data.channel;

        $this.active_client.reg_link = res.data.reg_link;
        $this.active_client.platform = res.data.platform;
        $this.active_client.link_to_deal = res.data.link_to_deal;

        $this.active_client.stage = res.data.stage;
        $this.stage_change = res.data.stage;

        $this.player_id_changed = $this.active_client.playerid;
        $this.tags = $this.active_client.tags;


        $this.chats.forEach(chat => {
          if (chat.id == $this.active_chat){
            console.log(chat.message_last__text.slice(0, 5));
            console.log(res.data.messages[0].text.slice(0, 5));
            if (chat.message_last__text.slice(0, 5) != res.data.messages[0].text.slice(0, 5)){
              chat.message_last__text = res.data.messages[0].text;
              chat.count_read = $this.countObjectsWithTrueKey(res.data.messages);
            }
          }
        });
      })
    },
    change_chat: function (chat) {
      this.active_chat = chat;
      this.mobile_position = "center";
      // var $this = this;
      this.messages = [];
      this.getFromDrafts();
      this.update_chat();
    },
    open_deal: function () {
      window.open(this.active_client.link_to_deal, '_blank').focus();
    },
    stopUpdater: function () {
      clearInterval(this.updater);
      clearInterval(this.updater_chat);
    },
    copyRegister: function () {
      navigator.clipboard.writeText(this.active_client.reg_link);
    },
    onFilter() {
      // if (this.search) {
      //   var arrayFiltered = this.chats.filter( (chat) => {
      //     return chat.name.toLowerCase().indexOf(this.search.toLowerCase()) != -1
      //   });
      //   this.filtered_chats = arrayFiltered;
      // }
      // else {
      // }
      this.filtered_chats = this.chats;
      if (this.on_chats_update == false){
        clearTimeout(this.filter_timer);
        setTimeout(() => {
          this.update_messages();
        }, "5 second");
      }
    },
    get_date_to(date){
      // var date_getted = Date.parse(date);
      var dt = new Date(date);
      var dateFormat = new Intl.DateTimeFormat("ru", {
                        year: "2-digit",
                        month: "long",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit"
      });
      return dateFormat.format(dt).replace(" GMT+3", "");
    },
    contextMenu(e, message_id, text, mid) {
      //prevent the browser's default menu
      e.preventDefault();
      //show your menu
      ContextMenu.showContextMenu({
        x: e.x,
        y: e.y,
        items: [
          { 
            label: "Ответить",
            icon: "reply",
            iconFontClass: "material-symbols-rounded",
            onClick: () => {
              this.message_reply = {id: message_id, text: text};
            }
          },
          { 
            label: "Перевод (DeepL powered)",
            icon: "translate",
            iconFontClass: "material-symbols-rounded",
            onClick: () => {
              axios.get('https://mvpproject.io/message/translate/', {
                params: {
                  message_id: mid
                }
              });
            }
          },
          { 
            label: "Вставить ссылку на регистрацию",
            icon: "translate",
            iconFontClass: "material-symbols-rounded",
            onClick: () => {
              this.text_message += this.active_client.reg_link;
            }
          },
          { 
            label: "Прочитано",
            icon: "visibility",
            iconFontClass: "material-symbols-rounded",
            onClick: () => {
              this.chats.forEach(chat => {
                if (chat.id == this.active_chat){
                  chat.count_read = 0;
                }
              });
              this.messages.forEach(message => {
                message.read = true;
              });
              var $this = this;
              const formData = new FormData();
              formData.append('chat_id', this.active_chat);
              axios.post('https://dunkback.mvpproject.io/message/read/', formData,
                {
                  headers:{
                    'Content-Type': 'multipart/form-data'
                  }
              }).then(function() {
                
                $this.update_messages();
              });
            }
          },
          { 
            label: "Скопировать",
            icon: "content_copy",
            iconFontClass: "material-symbols-rounded",
            onClick: () => {
              navigator.clipboard.writeText(text);
            }
          }
        ],
        theme: "mac"
      }); 
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
