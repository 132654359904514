<template>
  <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
  <div class="form_remove_container" v-if="remover">
    <div class="form_remove">
      <div class="title" v-if="remainder == 0">Удалить аккаунт {{account_to_remove}}?</div>
      <div class="title" v-if="remainder > 0">Удалить аккаунт {{account_to_remove}}? Остаток средств за аккаунт в размере {{remainder}}$ вернется на ваш баланс</div>
      <div class="buttons">
        <button @click="remove(account_to_remove)">Удалить</button>
        <button @click="remover = !remover">Отменить</button>
      </div>
    </div>
  </div>
  <div class="form_remove_container" v-if="notify">
    <div class="form_remove">
      <div class="title">{{notify_text}}</div>
    </div>
  </div>
  <div class="channel">
    <div class="container_ekk">
      <button @click="add_popup=!add_popup">
        <img alt="Vue logo" src="../assets/add_channel.svg">
        Добавить
      </button>
      <div class="popup" v-if="add_popup">
        <button @click="change_type_form('bot')">
          <img src="../assets/bot_icon.svg" alt="">
          Бот (0$)
        </button>
        <button @click="change_type_form('send_code')">
          <img src="../assets/account_icon.svg" alt="">
          Аккаунт (10$)
        </button>
      </div>
    </div>
    <div class="channels-list">
      <ul>
        <li class="inactive" v-for="account in accounts_false" :key="account">
          <div class="account">
            <div class="left">
              <img alt="Vue logo" src="../assets/error_status.svg">
              {{account.text}}
            </div>
            <div class="right">
              Статус: <span>{{account.error_code}}</span>
            </div>
          </div>
          <button v-if="account.error_code == 'Для входа требуется пароль' & account.account_type == 'account'" @click="account_fix(account)">Ввести пароль</button>
          <button v-if="account.error_code == 'Введенный пароль неверен' & account.account_type == 'account'" @click="account_fix(account)">Ввести пароль</button>
          <button v-else-if="account.account_type == 'account'" @click="account_fix(account)">Релогин</button>
          <button @click="remove_try(account.id)"><img src="../assets/remove-white.svg" alt=""></button>
        </li>
        <li class="active" v-for="account in accounts" :key="account">
          <div class="account">
            <div class="left">
              <img v-if="account.account_type == 'account'" alt="Vue logo" src="../assets/account_icon.svg">
              <img v-if="account.account_type == 'bot'" alt="Vue logo" src="../assets/bot_icon.svg">
              {{account.text}}
            </div>
          </div>
          <button @click="remove_try(account.id)"><img src="../assets/remove.svg" alt=""></button>
          <!-- <div class="text">
            {{account.text}}
          </div>
          <div class="right">
            <div class="remove relogin" @click="recovery_account(account.text)">Релогин</div>
            <div class="remove" @click="remove_try(account.id)">Удалить</div>
          </div> -->
        </li>
      </ul>
    </div>
    <!-- <div class="formadd type" v-if="form_step == 'type'">
      <button @click="change_type_form('bot')"><i class="material-symbols-rounded">robot</i>Бот</button>
      <button @click="change_type_form('send_code')"><i class="material-symbols-rounded">phone_iphone</i>Аккаунт</button>
    </div> -->
    <div class="formadd bot" v-if="form_step == 'bot'">
      <input type="text" v-model="bot_token" placeholder="Введите токен бота">
      <button @click="create_bot()"><i class="material-symbols-rounded">add_circle</i>Добавить бота (бесплатно)</button>
    </div>
    <div class="formadd send_code" v-if="form_step == 'send_code'">
      <input type="text" @input="phone_format" v-model="phone_number" placeholder="Введите номер телефона (70000000000)">
      <button @click="send_code()"><i class="material-symbols-rounded">add_circle</i>Добавить аккаунт (10$/месяц)</button>
    </div>
    <div class="formadd send_code" v-if="form_step == 'send_password'">
      <input type="text" v-model="code" placeholder="Введите код подтверждения">
      <input type="text" v-model="cloud_password" placeholder="Облачный пароль">
      <button @click="send_password()"><i class="material-symbols-rounded">add_circle</i>Добавить аккаунт (10$/месяц)</button>
    </div>
    <div class="formadd account" v-if="form_step == 'account'">
      <input type="text" v-model="code" placeholder="Введите код подтверждения">
      <button @click="create_account()"><i class="material-symbols-rounded">add_circle</i>Добавить аккаунт (10$/месяц)</button>
    </div>
  </div>
</template>
<style>
@font-face {
  font-family: 'Noto Sans';
  src: local('Noto Sans'), local('NotoSans'), url('../assets/fonts/noto/notosans.woff2') format('woff2'), url('../assets/fonts/noto/notosans.woff') format('woff'), url('../assets/fonts/noto/notosans.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Noto Sans';
  src: local('Noto Sans Italic'), local('NotoSans-Italic'), url('../assets/fonts/noto/notosansitalic.woff2') format('woff2'), url('../assets/fonts/noto/notosansitalic.woff') format('woff'), url('../assets/fonts/noto/notosansitalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Noto Sans';
  src: local('Noto Sans Bold'), local('NotoSans-Bold'), url('../assets/fonts/noto/notosansbold.woff2') format('woff2'), url('../assets/fonts/noto/notosansbold.woff') format('woff'), url('../assets/fonts/noto/notosansbold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Noto Sans';
  src: local('Noto Sans Bold Italic'), local('NotoSans-BoldItalic'), url('../assets/fonts/noto/notosansbolditalic.woff2') format('woff2'), url('../assets/fonts/noto/notosansbolditalic.woff') format('woff'), url('../assets/fonts/noto/notosansbolditalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}
  *{
    font-family: 'Noto Sans';
  }
  .container_ekk > .popup > button {
      display: flex;
      gap: 5px;
      align-items: center;
  }
  .container_ekk > .popup {
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      position: absolute;
      background: white;
      top: 45px;
      padding: 10px;
      min-width: 180px;
      border: 1px solid #D7D8DC;
  }
  .container_ekk {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    position: relative;
  }
  .container_ekk > .popup {
    position: absolute;
  }
  .container_ekk > button {
    display: flex;
    gap: 10px;
    background: #3575E6;
    padding: 10px 15px;
    border-radius: 5px;
    align-items: center;
    color: white;
    gap: 10px;
    font-weight: 600;
    font-size: .9em;
    text-transform: uppercase;
  }
  .channels-list > ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 15px;
  }
  .channels-list > ul > li.inactive, .channels-list > ul > li.active{
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  .channels-list > ul > li.inactive > .account, .channels-list > ul > li.active > .account {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px 15px;
    border-radius: 5px;
  }
  .channels-list > ul > li.active > .account{
    border: 1px solid #D7D8DC;
  }
  .channels-list > ul > li.active > button{
    border-radius: 5px;
    border: 1px solid #D7D8DC;
    padding: 10px;
  }
  .channels-list > ul > li.inactive > .account{
    background: #E32645;
    color: white;
  }
  .channels-list > ul > li.inactive > .account > .left > img, .channels-list > ul > li.active > .account > .left > img {
    width: 20px;
  }
  .channels-list > ul > li.inactive > .account > .left, .channels-list > ul > li.active > .account > .left {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .channels-list > ul > li.inactive > .account > .right{
    width: 230px;
    max-width: 230px;
    height: 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: right;
    overflow: hidden;
  }
  .channels-list > ul > li.inactive > .account > .right > span{
    font-weight: 600;
    font-size: .9em;
    color: white;
    max-width: 150px;
    overflow-x: hidden;
  }
  .channels-list > ul > li.inactive > button{
    background: #E32645;
    padding: 10px 15px;
    border-radius: 5px;
    font-weight: 600;
    font-size: .9em;
    color: white;
    text-transform: uppercase;
    text-wrap: nowrap;
  }
  .channels-list > ul > li > .right{
    display: flex;
    gap: 8px;
  }
  .channels-list > ul > li > .right > .remove{
    padding: 8px;
    background: #683131;
    border-radius: 5px;
    cursor: pointer;
  }
  .channels-list > ul > li > .right > .remove:hover{
    background: #783939;
  }
  .channels-list > ul > li > .right > .remove.relogin{
    padding: 8px;
    background: #f5d3d3;
    color: #683131;
    border-radius: 5px;
    cursor: pointer;
  }
  .channels-list > ul > li > .right > .remove.relogin:hover{
    background: #f1c5c5;
    color: #683131;
  }
  .channel{
    font-size: .75em;
  }
  .formadd{
    display: flex;
    flex-direction: column;
    gap: 5px
  }

  .formadd > input{
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #CFDBD5;
    min-height: 18px;
    resize: none;
  }

  .formadd > button{
    padding: 10px;
    background: linear-gradient(180deg,#a04b4a,#884040);
    border: 0;
    color: white;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    border-radius: 8px;
  }
  .formadd > button > i{
    font-size: .9em;
  }
  
    .form_remove_container {
      position: fixed;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      background: #1e1e1e5e;
      display: flex;
      justify-content: center;
      padding: 20px;
      align-items: center;
  }
  .form_remove {
    padding: 20px;
    background: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.form_remove > .title {
  font-size: .9em;
  font-weight: 500;
}
.form_remove > .buttons > button {
  padding: 10px;
  background: linear-gradient(180deg,#a04b4a,#884040);
  color: white;
  border-radius: 8px;
  font-size: .75em;
}
.form_remove > .buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
</style>
<script>
import axios from 'axios';
export default {
  name: 'ChannelAddView',
  components: {
  },
  data() {
    return {
      add_popup: false,
      notify: false,
      notify_text: "Грузим",
      form_type: true,
      form_bot: false,
      form_send_code: false,
      form_account: false,
      form_step: "",
      account_type: null,
      text_message: "",
      phone_number: "",
      code: "",
      bot_token: "",
      accounts: [],
      accounts_false: [],
      remover: false,
      remainder: 0,
      domain_push: "dunkback.mvpproject.io",
      account_to_remove: 0,
      cloud_password: ""
    }
  },
  mounted() {
        var $this = this;
        let urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('token')){
          this.token = urlParams.get('token');
        }
    
        axios.get('https://mvpproject.io/accounts/get/?token=' + this.token).then(function(res) {
          $this.accounts = res.data.accounts;
        });
        axios.get('https://mvpproject.io/accounts/get/false/?token=' + this.token).then(function(res) {
          $this.accounts_false = res.data.accounts;
        });
        axios.get('https://mvpproject.io/api/profile/get/', {
          headers: {
            'Authorization': `${$this.token}` 
          }
        }).then(function(res) {
          if (res.data.dunk && "hot_dunk_server" in res.data.dunk){
            $this.domain_push = res.data.dunk.hot_dunk_server;
          }
        });
  },
  unmounted() {
    
  },
  methods: {
    update_accounts: function () {
      var $this = this;
      axios.get('https://mvpproject.io/accounts/get/?token=' + this.token).then(function(res) {
          $this.accounts = res.data.accounts;
       });
      axios.get('https://mvpproject.io/accounts/get/false/?token=' + this.token).then(function(res) {
          $this.accounts_false = res.data.accounts;
      });
    },
    phone_format: function (event) {
      this.phone_number = event.target.value.replace(/\D/g, '');
    },
    recovery_account: function (text) {
      const formData = new FormData();
      this.phone_number = text.split(/\s/)[1];
      console.log(this.phone_number);
      formData.append('phone', text.split(/\s/)[1]);
      formData.append('token', this.token);
      var $this = this;
      $this.notify = true;
      $this.notify_text = "Подождите минутку, отправляем код";
      // console.log(phoneNumber);
      axios.post(`https://${this.domain_push}/client/account/code/send/`, formData).then(function(res) {
        if (res.data.status == false){
          $this.$notify({type: "error", text: res.data.error_code});
        }
        else {
          $this.change_type_form('account');
        }
        $this.notify = false;
      });
    },
    remove_try: function (acc_id) {
      var $this = this;
      axios.get('https://mvpproject.io/dunk/account/remove/?account=' + acc_id + '&token=' + $this.token).then(function(res) {
        if (res.data.status == true){
          $this.remainder = res.data.remainder;
          $this.remover = true;
          $this.add_popup = false;
          $this.account_to_remove = acc_id;
          $this.update_accounts();
        }
        else{
          $this.$notify({type: "error", text: res.data.error});
        }
      });
    },
    remove: function (acc_id) {
      var $this = this;
      axios.get('https://mvpproject.io/dunk/account/remove/?account=' + acc_id + '&token=' + $this.token + '&confirm=true').then(function(res) {
        if (res.data.status == true){
          $this.remainder = 0;
          $this.remover = false;
          $this.account_to_remove = 0;
          let urlParams = new URLSearchParams(window.location.search);
          if (urlParams.has('token')){
            $this.token = urlParams.get('token');
          }
          $this.update_accounts();
        }
        else{
          $this.$notify({type: "error", text: res.data.error});
        }
      });
    },
    change_type_form: function (type) {
      this.form_step = type;
    },
    create_bot: function () {
      if (this.bot_token.length == 0){
        return
      }

      const formData = new FormData();
      formData.append('api_key', this.bot_token);
      formData.append('token', this.token);
      var $this = this;
      $this.notify = true;
      $this.add_popup = false;
      $this.notify_text = "Входим в вашего бота, подождите секунду"
      axios.post(`https://${this.domain_push}/client/bot/create/`, formData).then(function(res) {
        if (res.data.status == false){
          $this.$notify({type: "error", text: res.data.error_code});
        }
        else{
          $this.change_type_form('type');
          $this.update_accounts();
        }
        $this.notify = false;
      });
    },
    send_code: function () {
      if (this.phone_number.length == 0){
        return
      }
      const formData = new FormData();
      formData.append('phone', this.phone_number);
      formData.append('token', this.token);
      var $this = this;
      $this.notify = true;
      $this.add_popup = false;
      $this.notify_text = "Подождите минутку, отправляем код"
      axios.post(`https://${this.domain_push}/client/account/code/send/`, formData).then(function(res) {
        if (res.data.status == false){
          console.log(res.data.error_code);
          $this.$notify({type: "error", text: res.data.error_code});
        }
        else{
          $this.change_type_form('account');
          $this.update_accounts();
        }
        $this.notify = false;
      });
    },
    create_account: function () {
      if (this.code.length == 0){
        return
      }
      const formData = new FormData();
      formData.append('phone', this.phone_number);
      formData.append('code', this.code);
      var $this = this;
      $this.notify = true;
      $this.add_popup = false;
      $this.notify_text = "Входим в ваш аккаунт, подождите секунду"
      axios.post(`https://${this.domain_push}/client/account/create/`, formData).then(function(res) {
        if (res.data.status == false){
          if (res.data.error_code == "Для входа требуется пароль"){
            $this.change_type_form('send_password');
            $this.update_accounts();
          }
          console.log(res.data.error_code);
          $this.$notify({type: "error", text: res.data.error_code});
        }
        else{
          $this.change_type_form('type');
          $this.update_accounts();
        }
        $this.notify = false;
        $this.cloud_password = "";
      });
    },
    send_password: function () {
      if (this.code.length == 0){
        return
      }
      const formData = new FormData();
      formData.append('phone', this.phone_number);
      formData.append('code', this.code);
      formData.append('cloud_password', this.cloud_password);
      var $this = this;
      $this.notify = true;
      $this.add_popup = false;
      $this.notify_text = "Входим в ваш аккаунт, подождите секунду"
      axios.post(`https://${this.domain_push}/client/account/password/send/`, formData).then(function(res) {
        if (res.data.status == false){
          $this.$notify({type: "error", text: res.data.error_code});
        }
        else{
          $this.change_type_form('type');
          $this.update_accounts();
        }
        $this.notify = false;
        $this.cloud_password = "";
      });
    },
    account_fix: function (account) {
      this.phone_number = account.phone_number;
      if (account.error_code == "Для входа требуется пароль" || account.error_code == "Введенный пароль неверен"){
        this.change_type_form('send_password');
        this.update_accounts();
      }
      else{
        this.send_code();
        this.change_type_form('account');
        this.update_accounts();
      }
    },
  }
}
</script>