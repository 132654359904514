<template>
  <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
  <div class="container_stages">
    <div class="lines">
            <div class="line" v-for="line in lines" :key="line">
                <div class="title">
                  <input type="text" :value="line.title" @change="updateLineTitle(line.id, $event.target.value);">
                </div>
                <div class="stages">
                    <div class="stage" v-for="stage in line.stages" :key="stage">
                        <div class="title" style="background: #e3e3e3;">
                          <input type="text" :value="stage.title" @change="updateStageTitle(stage.id, $event.target.value)">
                        </div>
                        <div class="cntxt">
                          <div class="input">
                            <input type="checkbox" :checked="stage.standart" @change="updateDefault(stage.id, $event.target.checked)">
                            <label>Стандартная</label>
                          </div>
                          <div class="input">
                            <input type="checkbox" :checked="stage.ignore" @change="updateIgnore(stage.id, $event.target.checked)">
                            <label>Игнор</label>
                          </div>
                          
                        </div>
                        <div class="logic-item" v-for="robot in stage.robots" :key="robot">
                          <div class="input">
                            <label for="">Если</label>
                            <select v-model="robot.if_condition" @change="if_condition_change(robot.id, $event.target.value)">
                              <option value="">-</option>
                              <option value="register">Регистрация</option>
                              <option value="deposit">Депозит</option>
                              <option value="redeposit">Повторный депозит</option>
                              <option value="client_message">Сообщение от клиента</option>
                              <option value="manager_message">Сообщение от менеджера</option>
                              <option value="client_message_no">Нет ответа от клиента</option>
                              <option value="manager_message_no">Нет ответа от менеджера</option>
                              <option value="current_stage">Текущая стадия</option>
                            </select>
                          </div>
                          <div class="input" v-if="robot.if_condition == 'client_message_no' | robot.if_condition == 'manager_message_no'" @change="timer_change(robot.id, $event.target.value)">
                            <label for="">В течении</label>
                            <textarea v-model="robot.timer"></textarea>
                          </div>
                          <div class="input">
                            <label for="">То</label>
                            <select v-model="robot.then_condition" @change="then_condition_change(robot.id, $event.target.value)">
                              <option value="">-</option>
                              <option value="transfer">Перевести на стадию</option>
                              <option value="send_message">Отправить сообщение</option>
                            </select>
                          </div>
                          <div class="input" v-if="robot.then_condition == 'transfer'">
                            <select v-model="robot.line_to_id" @change="transfer_change(robot.id, $event.target.value)">
                              <option :value="stage_obj.id.toString()" v-for="stage_obj in stages_list" :key="stage_obj">{{stage_obj.title}}</option>
                            </select>
                          </div>
                          <div class="input" v-if="robot.then_condition == 'send_message'" @change="message_change(robot.id, $event.target.value)">
                            <!-- <label for="">То</label> -->
                            <textarea v-model="robot.message"></textarea>
                          </div>
                          <div @click="removeRobot(robot.id)" class="button_accent action-remove-stage-created">Удалить</div>
                        </div>
                        <div @click="createRobot(stage.id)" class="button_accent">Добавить</div>
                        <div @click="removeStage(stage.id)" class="button_accent">Удалить</div>
                    </div>
                </div>
                <div @click="createStage(line.id)" class="button_accent addstage">Добавить стадию</div>
                <div @click="removeLine(line.id)" class="button_accent addstage">Удалить линию</div>
            </div>
    </div>
    <div @click="createLine()" class="button_accent addline">Добавить линию</div>
  </div>
</template>
<style>
  .container_stages{
    font-size: .75em;
  }
  .button_accent {
    background: #a04b4b;
    box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.15) inset;
    border-radius: 10px;
    color: white;
    padding: 10px;
    padding-bottom: 13px;
    font-size: .8em;
    text-align: center;
    font-weight: 500;
    border: 0;
    cursor: pointer;
  }

  .container_stages{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .lines{
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .lines > .line{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    border-radius: 10px;
    background: white;
    border: 1.5px solid #d9d9d9;
  }

  .lines > .line > .title > input{
    border: 0;
    font-size: 1em;
  }

  .lines > .line > .stages {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  .lines > .line > .stages > .stage {
    background: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px
  }

  .lines > .line > .stages > .stage > .logic {
    display: flex;
    gap: 10px;
    padding: 10px;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
  }

  .lines > .line > .stages > .stage > .logic > .logic-item-created {
    font-size: .85em;
    padding: 10px;
    border: 1px solid #7777775e;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }

  .stage >.title {
    padding: 10px;
    font-size: .85em;
    border-radius: 10px;
    border: 2px solid #3131312b;
  }
  .stage >.title>input {
    border: 0;
    background: unset;
    font-weight: 600;
  }
  .logic-item {
    display: flex;
    flex-direction: column;
    background: #e3e3e3;
    padding: 10px;
    border-radius: 10px;
    gap: 10px;
  }
  .logic-item > .input {
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: .85em;
  }
  .logic-item>.input > input[type="text"], .logic-item>.input > select, .logic-item>.input > textarea {
    padding: 6px;
    border-radius: 7px;
    border: 1px solid #acacac;
    resize: none;
  }
</style>
<script>
// import 'animate.css';
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import axios from 'axios';
// import { Fancybox } from "@fancyapps/ui";
// import "@fancyapps/ui/dist/fancybox/fancybox.css";
// import ContextMenu from '@imengyu/vue3-context-menu';
import axios from 'axios';
export default {
  name: 'StageSettingsView',
  components: {
  },
  data() {
    return {
      token: null,
      lines: [],
      stages_list: []
    }
  },
  mounted() {
    let urlParams = new URLSearchParams(window.location.search);
    this.token = urlParams.get('token');
    this.updateStage();
  },
  unmounted() {
  },
  methods: {
    updateStage() {
      var $this = this;
      var params = {
        token: this.token
      };
      axios.get('https://mvpproject.io/business/api/list/', {params}
      ).then(function(res) {
        $this.lines = res.data;
        var temp_stages_list = [];
        $this.lines.forEach(line => {
          line.stages.forEach(stage => {
            temp_stages_list.push(stage);
          });
        });
        $this.stages_list = temp_stages_list;
      });
    },

    updateLineTitle(line_id, title) {
      var $this = this;
      var params = {
        token: this.token,
        id: line_id,
        title: title
      };
      axios.get('https://mvpproject.io/business/api/line/change/', {params}
      ).then(function(res) {
        $this.lines = res.data;
      });
    },
    updateStageTitle(stage_id, title) {
      var $this = this;
      var params = {
        token: this.token,
        id: stage_id,
        title: title
      };
      axios.get('https://mvpproject.io/business/api/stage/change/', {params}
      ).then(function(res) {
        $this.lines = res.data;
      });
    },



    if_condition_change(robot_id, text) {
      var $this = this;
      var params = {
        token: this.token,
        id: robot_id,
        value: text
      };
      axios.get('https://mvpproject.io/business/api/robot/change/if/', {params}
      ).then(function(res) {
        $this.lines = res.data;
      });
    },
    timer_change(robot_id, text) {
      var $this = this;
      var params = {
        token: this.token,
        id: robot_id,
        value: text
      };
      axios.get('https://mvpproject.io/business/api/robot/change/timer/', {params}
      ).then(function(res) {
        $this.lines = res.data;
      });
    },
    then_condition_change(robot_id, text) {
      var $this = this;
      var params = {
        token: this.token,
        id: robot_id,
        value: text
      };
      axios.get('https://mvpproject.io/business/api/robot/change/then/', {params}
      ).then(function(res) {
        $this.lines = res.data;
      });
    },
    transfer_change(robot_id, text) {
      var $this = this;
      var params = {
        token: this.token,
        id: robot_id,
        value: text
      };
      axios.get('https://mvpproject.io/business/api/robot/change/transfer/', {params}
      ).then(function(res) {
        $this.lines = res.data;
      });
    },
    message_change(robot_id, text) {
      var $this = this;
      var params = {
        token: this.token,
        id: robot_id,
        value: text
      };
      axios.get('https://mvpproject.io/business/api/robot/change/message/', {params}
      ).then(function(res) {
        $this.lines = res.data;
      });
    },



    removeLine(line_id) {
      var $this = this;
      var params = {
        token: this.token,
        id: line_id
      };
      axios.get('https://mvpproject.io/business/api/line/remove/', {params}
      ).then(function(res) {
        $this.lines = res.data;
      });
    },
    removeStage(stage_id) {
      var $this = this;
      var params = {
        token: this.token,
        id: stage_id
      };
      axios.get('https://mvpproject.io/business/api/stage/remove/', {params}
      ).then(function(res) {
        $this.lines = res.data;
      });
    },
    removeRobot(robot_id) {
      var $this = this;
      var params = {
        token: this.token,
        id: robot_id
      };
      axios.get('https://mvpproject.io/business/api/robot/remove/', {params}
      ).then(function(res) {
        $this.lines = res.data;
      });
    },

    createStage(line_id) {
      var $this = this;
      var params = {
        token: this.token,
        line: line_id
      };
      axios.get('https://mvpproject.io/business/api/stage/create/', {params}
      ).then(function(res) {
        $this.lines = res.data;
      });
    },
    createLine() {
      var $this = this;
      var params = {
        token: this.token
      };
      axios.get('https://mvpproject.io/business/api/line/create/', {params}
      ).then(function(res) {
        $this.lines = res.data;
      });
    },
    createRobot(stage_id) {
      var $this = this;
      var params = {
        token: this.token,
        stage: stage_id,
        if_conditions: "no_client",
        then_conditions: "transfer"
      };
      axios.get('https://mvpproject.io/business/api/robot/create/', {params}
      ).then(function(res) {
        $this.lines = res.data;
      });
    },

    updateIgnore(stage_id, value) {
      var $this = this;
      var params = {
        token: this.token,
        stage: stage_id,
        value: value
      };
      axios.get('https://mvpproject.io/business/api/stage/update/ignore/', {params}
      ).then(function(res) {
        $this.lines = res.data;
      });
    },
    updateDefault(stage_id, value) {
      var $this = this;
      var params = {
        token: this.token,
        stage: stage_id,
        value: value
      };
      axios.get('https://mvpproject.io/business/api/stage/update/default/', {params}
      ).then(function(res) {
        $this.lines = res.data;
      });
    },

    error() {
      console.log("1");
      // this.$notify({type: "error", text: "У вас нет прав на совершение этого действия!"});
    }
  }
}
</script>
