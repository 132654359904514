import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ChannelAddView from '../views/ChannelAddView.vue'
import StageSettingsView from '../views/StageSettingsView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/:token/',
    name: 'hometoken',
    component: HomeView
  },
  {
    path: '/channel/add/',
    name: 'channel_add',
    component: ChannelAddView
  },
  {
    path: '/stages/',
    name: 'stages',
    component: StageSettingsView
  }
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
