import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Notifications from '@kyvg/vue3-notification'
import '@imengyu/vue3-context-menu/lib/vue3-context-menu.css'
import ContextMenu from '@imengyu/vue3-context-menu'
import DiscordPicker from 'vue3-discordpicker'
// import VueMeta from 'vue-3-meta'

createApp(App).use(store).use(router).use(Notifications).use(ContextMenu).use(DiscordPicker).mount('#app')
