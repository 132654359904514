<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view/>
  <notifications width="100%" />
</template>


<style>
*{
  padding: 0;
  margin: 0;
  list-style: none;
  text-decoration: none;
}

.vue-notification {
  font-size: 19px;
}

@font-face {
    font-family: 'Fira Sans';
    src: url('assets/fonts/fira/FiraSans-Light.eot');
    src: local('Fira Sans Light'), local('FiraSans-Light'),
        url('assets/fonts/fira/FiraSans-Light.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/fira/FiraSans-Light.woff2') format('woff2'),
        url('assets/fonts/fira/FiraSans-Light.woff') format('woff'),
        url('assets/fonts/fira/FiraSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Fira Sans';
    src: url('assets/fonts/fira/FiraSans-Regular.eot');
    src: local('Fira Sans Regular'), local('FiraSans-Regular'),
        url('assets/fonts/fira/FiraSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/fira/FiraSans-Regular.woff2') format('woff2'),
        url('assets/fonts/fira/FiraSans-Regular.woff') format('woff'),
        url('assets/fonts/fira/FiraSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Fira Sans';
    src: url('assets/fonts/fira/FiraSans-LightItalic.eot');
    src: local('Fira Sans Light Italic'), local('FiraSans-LightItalic'),
        url('assets/fonts/fira/FiraSans-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/fira/FiraSans-LightItalic.woff2') format('woff2'),
        url('assets/fonts/fira/FiraSans-LightItalic.woff') format('woff'),
        url('assets/fonts/fira/FiraSans-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Fira Sans';
    src: url('assets/fonts/fira/FiraSans-Thin.eot');
    src: local('Fira Sans Thin'), local('FiraSans-Thin'),
        url('assets/fonts/fira/FiraSans-Thin.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/fira/FiraSans-Thin.woff2') format('woff2'),
        url('assets/fonts/fira/FiraSans-Thin.woff') format('woff'),
        url('assets/fonts/fira/FiraSans-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Fira Sans';
    src: url('assets/fonts/fira/FiraSans-ThinItalic.eot');
    src: local('Fira Sans Thin Italic'), local('FiraSans-ThinItalic'),
        url('assets/fonts/fira/FiraSans-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/fira/FiraSans-ThinItalic.woff2') format('woff2'),
        url('assets/fonts/fira/FiraSans-ThinItalic.woff') format('woff'),
        url('assets/fonts/fira/FiraSans-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Fira Sans';
    src: url('assets/fonts/fira/FiraSans-ExtraBoldItalic.eot');
    src: local('Fira Sans ExtraBold Italic'), local('FiraSans-ExtraBoldItalic'),
        url('assets/fonts/fira/FiraSans-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/fira/FiraSans-ExtraBoldItalic.woff2') format('woff2'),
        url('assets/fonts/fira/FiraSans-ExtraBoldItalic.woff') format('woff'),
        url('assets/fonts/fira/FiraSans-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Fira Sans';
    src: url('assets/fonts/fira/FiraSans-SemiBold.eot');
    src: local('Fira Sans SemiBold'), local('FiraSans-SemiBold'),
        url('assets/fonts/fira/FiraSans-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/fira/FiraSans-SemiBold.woff2') format('woff2'),
        url('assets/fonts/fira/FiraSans-SemiBold.woff') format('woff'),
        url('assets/fonts/fira/FiraSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Fira Sans';
    src: url('assets/fonts/fira/FiraSans-ExtraLightItalic.eot');
    src: local('Fira Sans ExtraLight Italic'), local('FiraSans-ExtraLightItalic'),
        url('assets/fonts/fira/FiraSans-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/fira/FiraSans-ExtraLightItalic.woff2') format('woff2'),
        url('assets/fonts/fira/FiraSans-ExtraLightItalic.woff') format('woff'),
        url('assets/fonts/fira/FiraSans-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Fira Sans';
    src: url('assets/fonts/fira/FiraSans-ExtraBold.eot');
    src: local('Fira Sans ExtraBold'), local('FiraSans-ExtraBold'),
        url('assets/fonts/fira/FiraSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/fira/FiraSans-ExtraBold.woff2') format('woff2'),
        url('assets/fonts/fira/FiraSans-ExtraBold.woff') format('woff'),
        url('assets/fonts/fira/FiraSans-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Fira Sans';
    src: url('assets/fonts/fira/FiraSans-BlackItalic.eot');
    src: local('Fira Sans Black Italic'), local('FiraSans-BlackItalic'),
        url('assets/fonts/fira/FiraSans-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/fira/FiraSans-BlackItalic.woff2') format('woff2'),
        url('assets/fonts/fira/FiraSans-BlackItalic.woff') format('woff'),
        url('assets/fonts/fira/FiraSans-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Fira Sans';
    src: url('assets/fonts/fira/FiraSans-Medium.eot');
    src: local('Fira Sans Medium'), local('FiraSans-Medium'),
        url('assets/fonts/fira/FiraSans-Medium.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/fira/FiraSans-Medium.woff2') format('woff2'),
        url('assets/fonts/fira/FiraSans-Medium.woff') format('woff'),
        url('assets/fonts/fira/FiraSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Fira Sans';
    src: url('assets/fonts/fira/FiraSans-MediumItalic.eot');
    src: local('Fira Sans Medium Italic'), local('FiraSans-MediumItalic'),
        url('assets/fonts/fira/FiraSans-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/fira/FiraSans-MediumItalic.woff2') format('woff2'),
        url('assets/fonts/fira/FiraSans-MediumItalic.woff') format('woff'),
        url('assets/fonts/fira/FiraSans-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Fira Sans';
    src: url('assets/fonts/fira/FiraSans-SemiBoldItalic.eot');
    src: local('Fira Sans SemiBold Italic'), local('FiraSans-SemiBoldItalic'),
        url('assets/fonts/fira/FiraSans-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/fira/FiraSans-SemiBoldItalic.woff2') format('woff2'),
        url('assets/fonts/fira/FiraSans-SemiBoldItalic.woff') format('woff'),
        url('assets/fonts/fira/FiraSans-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Fira Sans';
    src: url('assets/fonts/fira/FiraSans-Bold.eot');
    src: local('Fira Sans Bold'), local('FiraSans-Bold'),
        url('assets/fonts/fira/FiraSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/fira/FiraSans-Bold.woff2') format('woff2'),
        url('assets/fonts/fira/FiraSans-Bold.woff') format('woff'),
        url('assets/fonts/fira/FiraSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Fira Sans';
    src: url('assets/fonts/fira/FiraSans-Black.eot');
    src: local('Fira Sans Black'), local('FiraSans-Black'),
        url('assets/fonts/fira/FiraSans-Black.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/fira/FiraSans-Black.woff2') format('woff2'),
        url('assets/fonts/fira/FiraSans-Black.woff') format('woff'),
        url('assets/fonts/fira/FiraSans-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Fira Sans';
    src: url('assets/fonts/fira/FiraSans-BoldItalic.eot');
    src: local('Fira Sans Bold Italic'), local('FiraSans-BoldItalic'),
        url('assets/fonts/fira/FiraSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/fira/FiraSans-BoldItalic.woff2') format('woff2'),
        url('assets/fonts/fira/FiraSans-BoldItalic.woff') format('woff'),
        url('assets/fonts/fira/FiraSans-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Fira Sans';
    src: url('assets/fonts/fira/FiraSans-Italic.eot');
    src: local('Fira Sans Italic'), local('FiraSans-Italic'),
        url('assets/fonts/fira/FiraSans-Italic.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/fira/FiraSans-Italic.woff2') format('woff2'),
        url('assets/fonts/fira/FiraSans-Italic.woff') format('woff'),
        url('assets/fonts/fira/FiraSans-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Fira Sans';
    src: url('assets/fonts/fira/FiraSans-ExtraLight.eot');
    src: local('Fira Sans ExtraLight'), local('FiraSans-ExtraLight'),
        url('assets/fonts/fira/FiraSans-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/fira/FiraSans-ExtraLight.woff2') format('woff2'),
        url('assets/fonts/fira/FiraSans-ExtraLight.woff') format('woff'),
        url('assets/fonts/fira/FiraSans-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}



/* This stylesheet generated by Transfonter (https://transfonter.org) on February 25, 2018 4:00 PM */

@font-face {
    font-family: 'Roboto';
    src: url('assets/fonts/roboto/Roboto-MediumItalic.eot');
    src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
        url('assets/fonts/roboto/Roboto-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/roboto/Roboto-MediumItalic.woff') format('woff'),
        url('assets/fonts/roboto/Roboto-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('assets/fonts/roboto/Roboto-Italic.eot');
    src: local('Roboto Italic'), local('Roboto-Italic'),
        url('assets/fonts/roboto/Roboto-Italic.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/roboto/Roboto-Italic.woff') format('woff'),
        url('assets/fonts/roboto/Roboto-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('assets/fonts/roboto/Roboto-Bold.eot');
    src: local('Roboto Bold'), local('Roboto-Bold'),
        url('assets/fonts/roboto/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/roboto/Roboto-Bold.woff') format('woff'),
        url('assets/fonts/roboto/Roboto-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('assets/fonts/roboto/Roboto-Regular.eot');
    src: local('Roboto'), local('Roboto-Regular'),
        url('assets/fonts/roboto/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/roboto/Roboto-Regular.woff') format('woff'),
        url('assets/fonts/roboto/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('assets/fonts/roboto/Roboto-Medium.eot');
    src: local('Roboto Medium'), local('Roboto-Medium'),
        url('assets/fonts/roboto/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/roboto/Roboto-Medium.woff') format('woff'),
        url('assets/fonts/roboto/Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('assets/fonts/roboto/Roboto-BoldItalic.eot');
    src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'),
        url('assets/fonts/roboto/Roboto-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/roboto/Roboto-BoldItalic.woff') format('woff'),
        url('assets/fonts/roboto/Roboto-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('assets/fonts/roboto/Roboto-ThinItalic.eot');
    src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'),
        url('assets/fonts/roboto/Roboto-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/roboto/Roboto-ThinItalic.woff') format('woff'),
        url('assets/fonts/roboto/Roboto-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('assets/fonts/roboto/Roboto-Black.eot');
    src: local('Roboto Black'), local('Roboto-Black'),
        url('assets/fonts/roboto/Roboto-Black.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/roboto/Roboto-Black.woff') format('woff'),
        url('assets/fonts/roboto/Roboto-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('assets/fonts/roboto/Roboto-Light.eot');
    src: local('Roboto Light'), local('Roboto-Light'),
        url('assets/fonts/roboto/Roboto-Light.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/roboto/Roboto-Light.woff') format('woff'),
        url('assets/fonts/roboto/Roboto-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('assets/fonts/roboto/Roboto-LightItalic.eot');
    src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
        url('assets/fonts/roboto/Roboto-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/roboto/Roboto-LightItalic.woff') format('woff'),
        url('assets/fonts/roboto/Roboto-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('assets/fonts/roboto/Roboto-BlackItalic.eot');
    src: local('Roboto Black Italic'), local('Roboto-BlackItalic'),
        url('assets/fonts/roboto/Roboto-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/roboto/Roboto-BlackItalic.woff') format('woff'),
        url('assets/fonts/roboto/Roboto-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url('assets/fonts/roboto/Roboto-Thin.eot');
    src: local('Roboto Thin'), local('Roboto-Thin'),
        url('assets/fonts/roboto/Roboto-Thin.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/roboto/Roboto-Thin.woff') format('woff'),
        url('assets/fonts/roboto/Roboto-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}




#app {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-size: 1.15em;
}
</style>
